import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider, ConfigProvider } from "antd";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import Chart from "../../../client/niv_acuteboekje/interactions/simple/ChartNivSimple";
import ChartNivVerdenkingOpLongembolie from "../../../client/niv_acuteboekje/json/verdenking-op-longembolie";
import ButtonSchematic from "../../../client/niv_acuteboekje/ui/ButtonSchematic";

export default function ExampleMedicalVerdenkingOpLongembolie() {
  return (
    <LayoutExamples>
      <ConfigProvider>
        <Seo
          title="Medical example | Verdenking op longembolie"
          description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
        />
        <Row
          justify="center"
          align="middle"
          style={{
            backgroundColor: "#f1f3f5",
            minHeight: "80vh",
          }}
          className="styles-niv_acuteboekje styles-niv_acuteboekje-examples"
        >
          <Col span={24}>
            <Chart
              entry={ChartNivVerdenkingOpLongembolie}
              firstNodeId="node1"
            />
            <ButtonSchematic url="/chart/niv/acuteboekje/verdenking-op-longembolie/schematic/" />
          </Col>
        </Row>
        <Divider dashed style={{ margin: "0" }} />
        <Row
          justify="center"
          align="middle"
          style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
        >
          <Col>
            <ButtonsNavigation
              linkPrevious="Previous"
              linkPreviousTo="/examples/premium/example-medical-assessment"
              linkNext="Next example"
              linkNextTo="/examples/premium/example-reservation"
            />
          </Col>
        </Row>
      </ConfigProvider>
    </LayoutExamples>
  );
}
